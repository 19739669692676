
/*
 * SITE 
 * Page | Clinic 
 * 
 */

// import { TocFix } from "../tocfix";
// import Plyr from 'plyr';
// // import QRCode from 'qrcode';
// import { AdjustTrackingUrl } from "../adjust";
// import { AdjustTrackingQr } from "../adjust-qr";

//const url: string = 'https://www.sygnal.com?thisisatrackingurlthsfdsfsdsdatdoesstuff';


export class ClinicPage {

    constructor() {
    }
    
    init() {

        /**
         * Fix links for the ToC 
         */

        // let tocFix = new TocFix();
        // tocFix.init(); 

//         window['fsAttributes'] = window['fsAttributes'] || [];
//         window['fsAttributes'].push([
//           'toc',
//           (tableInstances) => {

//             tocFix.addToCCloseLinks();

// //            console.log('toc Successfully loaded!');
//           },
//         ]);

//         /**
//          * Wire up components embedded
//          * in Finsweet PRT RTB's
//          */

//         window['fsAttributes'] = window['fsAttributes'] || []; 
//         window['fsAttributes'].push([  
//           'richtext',   
//           (textInstances) => {   

//             this.initAfterFsPRTLib();
// //            console.log('richtext Successfully loaded!');      
//           },   
//         ]);    


        // // Look for all links with a [code] attribute
        // // and apply it to the link's querystring as ?code=VALUE. 

        // // Get all links with the 'code' attribute
        // const links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a[code]');

        // links.forEach((link: HTMLAnchorElement) => {
        //     // Get the value of the 'code' attribute
        //     const codeValue: string = link.getAttribute('code') || '';

        //     // Use the URL interface to handle the href
        //     const url: URL = new URL(link.href);
            
        //     // Use URLSearchParams to set the code parameter
        //     url.searchParams.set('code', codeValue);

        //     // Update the link href
        //     link.href = url.toString();
        // });
                    
    }

}  

    
    
    
    